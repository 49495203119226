
body {
  font-family: var(--montserrat);
}

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden;

  &.is-open-right {
    overflow: hidden;

    & .has-transition-push {
      transform: translateX(-16em);
    }
  }
}

.is-open-right .title-bar .menu-icon {

}

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background:var(--bg-color);

  &.is-transition-push {
    z-index: 12;
  }

  &.is-open {
    transform: translate(0, 0);
  }

  &.is-closed {
    visibility: hidden;
    width: 0;
  }
}

.position-right {
  top: 68px;
  right: 0;
  left: 0;
  bottom: 0;
  height: calc(100vh - 56px);
  overflow-y: auto;
  width: 100vw;
  transform: translateX(100vw);
  background: var(--bg-color);
  padding: 0 1em;
}


.-modal-opened {
  position: fixed;
}

@media only screen and (min-width: 48em) { //768px
  .-hidden-large {
    display: none!important;
  }
}

@media only screen and (max-width: 48.1em) { //768px
  .-hidden-small {
    display: none!important;
  }
}

.fs-m {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-l {
  font-size: 20px;
}
.fs-s {
  font-size: 14px;
}
.fs-xs {
  font-size: 13px;
}
.fs-xxs {
  font-size: 12px;
}
.normal {
  font-weight: 400;
}
.semi-bold {
  font-weight: 500;
}
.bold {
  font-weight: 600;
}
.extra-bold {
  font-weight: 600;
}
.text-main {
  color: var(--primary-color)
}
.text-secondary {
  color: var(--secondary-color)
}